import { FirmwareDropdownResult } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDeviceOverview/ParadiseDeviceFirmware/ParadiseDeviceFirmware"
import { ParadiseDeviceFirmwareConfirmation } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDeviceOverview/ParadiseDeviceFirmware/ParadiseDeviceFirmwareConfirmation"
import { Maybe } from "src/utils/tsUtil"

export function ParadiseDeviceClearFirmware({
  currentWantedFirmware,
}: {
  currentWantedFirmware: Maybe<FirmwareDropdownResult>
}) {
  if (!currentWantedFirmware) {
    return null
  }

  return (
    <ParadiseDeviceFirmwareConfirmation
      title="Currently wanted firmware"
      description={currentWantedFirmware.description}
      fwNumber={currentWantedFirmware.fwNumber}
      tags={currentWantedFirmware.tags}
    />
  )
}
