import { MultiPillSelectResult } from "src/components/Paradise/MultiPillSelect"
import { ParadiseSettingMultiPillSelect } from "src/components/Paradise/ParadiseSettings/ParadiseSettingMultiPillSelect"
import { PillsWrapper } from "src/components/Paradise/sharedStyles"
import { TSettingContainerOnSaveReturnType } from "src/components/Settings/Setting/SettingContainer"
import { usePutParadiseUpdateDeviceTags } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { MBadge } from "src/ui/MBadge/MBadge"

export function ParadiseDeviceTags({
  currentTags,
  deviceId,
}: {
  currentTags: string[]
  deviceId: string
}) {
  const putUpdateDeviceTags = usePutParadiseUpdateDeviceTags()

  async function handleSave(
    v: MultiPillSelectResult[]
  ): TSettingContainerOnSaveReturnType {
    try {
      await putUpdateDeviceTags.mutateAsync({
        deviceId,
        body: {
          tags: v.map((t) => t.id),
        },
      })

      return {
        isSuccess: true,
      }
    } catch (error) {
      return {
        isSuccess: false,
      }
    }
  }

  return (
    <ParadiseSettingMultiPillSelect
      title="Tags"
      displayValue={
        currentTags.length > 0 ? (
          <PillsWrapper>
            {currentTags.map((tag) => (
              <MBadge key={tag} size="small" color="neutral">
                {tag}
              </MBadge>
            ))}
          </PillsWrapper>
        ) : (
          "-"
        )
      }
      initialSelected={convertToMultiPillResult(currentTags)}
      options={convertToMultiPillResult(currentTags)}
      onSave={handleSave}
    />
  )
}

function convertToMultiPillResult(arr: string[]): MultiPillSelectResult[] {
  return arr.map((t) => ({ id: t, name: t }))
}
