import { useMemo, useState } from "react"

import { FirmwareDropdownResult } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDeviceOverview/ParadiseDeviceFirmware/ParadiseDeviceFirmware"
import { ParadiseDeviceFirmwareConfirmation } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDeviceOverview/ParadiseDeviceFirmware/ParadiseDeviceFirmwareConfirmation"
import { useFetchParadiseFirmwares } from "src/data/paradise/paradiseFirmwares/queries/paradiseFirmwareQueries"
import { MSelect } from "src/ui/MSelect/MSelect"
import { MText } from "src/ui/MText"
import { Maybe } from "src/utils/tsUtil"

export function ParadiseDeviceSelectFirmware({
  initialFirmware,
  hardwareVersion,
  onSelect,
}: {
  initialFirmware: Maybe<FirmwareDropdownResult>
  hardwareVersion: number
  onSelect: (selected: FirmwareDropdownResult) => void
}) {
  const [selectedFirmware, setSelectedFirmware] = useState(initialFirmware)
  const [searchedFirmware, setSearchedFirmware] = useState("")

  const fetchFirmwares = useFetchParadiseFirmwares({
    filter: {
      hardware_version: hardwareVersion,
    },
  })

  const options = useMemo(() => {
    if (fetchFirmwares.data) {
      return fetchFirmwares.data.firmwares.flatMap((f) => {
        if (
          f.description.toLowerCase().includes(searchedFirmware.toLowerCase())
        ) {
          return {
            id: f.description,
            name: f.description,
            tags: f.tags,
            description: f.description,
            fwNumber: f.name,
          }
        }

        return []
      })
    }

    return []
  }, [searchedFirmware, fetchFirmwares.data])

  return (
    <>
      <div>
        <MText variant="body">Select wanted firmware</MText>
        <MSelect
          value={selectedFirmware}
          options={options}
          onSearch={(input) => setSearchedFirmware(input)}
          onSelected={(selected) => {
            if (selected) {
              setSelectedFirmware(selected)
              onSelect(selected)
            }
          }}
          loading={fetchFirmwares.isLoading}
          debounceDelay={300}
        ></MSelect>
      </div>
      {selectedFirmware && selectedFirmware.id && (
        <ParadiseDeviceFirmwareConfirmation
          title="Wanted firmware"
          description={selectedFirmware.description}
          fwNumber={selectedFirmware.fwNumber}
          tags={selectedFirmware.tags}
        />
      )}
    </>
  )
}
