import { useState } from "react"

import { ParadiseButtonsPager } from "src/components/Paradise/ParadiseButtonsPager"
import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { ExpandableWrapper } from "src/components/Paradise/sharedStyles"
import {
  getPointEventType,
  pointEventTypes,
} from "src/data/devices/logic/deviceLogic"
import { useFetchParadiseDeviceEvents } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { DropdownSelect } from "src/ui/DropdownSelect/DropdownSelect"
import { MBadge } from "src/ui/MBadge/MBadge"
import { formatDate } from "src/utils/l10n"

const LIMIT = 50

export function ParadiseDeviceEvents({ deviceId }: { deviceId: string }) {
  const [filter, setFilter] = useState<{
    eventType: number | null
  }>({
    eventType: null,
  })
  const [filteredEventTypes, setFilteredEventTypes] = useState(() =>
    Object.entries(pointEventTypes)
  )
  const [offset, setOffset] = useState(0)

  const fetchDeviceEvents = useFetchParadiseDeviceEvents({
    deviceId,
    filter: {
      offset,
      limit: LIMIT,
      event_type: filter.eventType ?? undefined,
    },
  })

  const { data: nextData } = useFetchParadiseDeviceEvents({
    deviceId,
    filter: {
      offset: offset + LIMIT,
      limit: LIMIT,
      event_type: filter.eventType ?? undefined,
    },
  })

  function handleEventTypeSearch(input: string) {
    const availableEventTypes = Object.entries(pointEventTypes)

    setFilteredEventTypes(
      availableEventTypes.filter(([key, value]) =>
        value.toLowerCase().includes(input.toLowerCase())
      )
    )
  }

  const headers = [
    <div key={"type"}>Type</div>,
    <div key={"received-at"}>Received at</div>,
    <div key={"recorded-at"}>Recorded at</div>,
    <div key={"value"}>Value</div>,
    <div key={"fw-version"}>FW version</div>,
  ]

  const rows =
    fetchDeviceEvents.data?.events.map((event) => (
      <>
        <div>
          <div>
            <MBadge size="small" color="neutral">
              {getPointEventType(event.type) ?? ""}
            </MBadge>
          </div>
        </div>
        <div>
          {formatDate({
            date: new Date(event.received_at).toISOString(),
            timezone: "utc",
          })}
        </div>
        <div>
          {formatDate({
            date: new Date(event.recorded_at).toISOString(),
            timezone: "utc",
          })}
        </div>
        <div>{event.value}</div>
        <div>{event.fw_version}</div>
      </>
    )) ?? []

  return (
    <div>
      <ParadiseDetailsSection title="Device events" />
      <ExpandableWrapper>
        <div>
          <DropdownSelect
            label={
              filter.eventType
                ? pointEventTypes[filter.eventType]
                : "Event type"
            }
            options={filteredEventTypes.map(([key, value]) => ({
              value: key,
              label: value,
            }))}
            placeholder="Search"
            selectedValue={filter.eventType?.toString() || ""}
            onSearch={handleEventTypeSearch}
            onChange={({ option }) => {
              setFilter((prev) => ({
                ...prev,
                eventType: parseInt(option.value),
              }))
            }}
            onClearSelection={() =>
              setFilter((prev) => ({ ...prev, eventType: null }))
            }
            debounceDelay={0}
          />
        </div>
        <ParadiseTable header={headers} rows={rows} />
        <ParadiseButtonsPager
          limit={LIMIT}
          offset={offset}
          setOffset={setOffset}
          hasNextPage={nextData && nextData.events.length > 0}
        />
      </ExpandableWrapper>
    </div>
  )
}
