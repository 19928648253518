import { useState } from "react"

import { ParadiseMultipleValuesField } from "src/components/Paradise/ParadiseClients/ParadiseCreateClientDialog/ParadiseMultipleValuesField"
import {
  SettingContainer,
  TSettingContainerOnSaveReturnType,
} from "src/components/Settings/Setting/SettingContainer"
import { spacing } from "src/ui/spacing"

export function ParadiseSettingMultiValueFields({
  title,
  displayValue,
  initialTextFields,
  buttonLabel,
  onSave,
  required,
}: {
  title: React.ReactNode
  displayValue: React.ReactNode
  initialTextFields: string[]
  buttonLabel: string
  onSave: (v: string[]) => TSettingContainerOnSaveReturnType
  required?: boolean
}) {
  const [saveValue, setSaveValue] = useState<string[]>(initialTextFields)

  return (
    <SettingContainer
      title={title}
      displayValue={displayValue}
      gap={spacing.XS2}
      titleProps={{
        type: "nano",
        color: "secondary",
      }}
      InputComponent={() => (
        <ParadiseMultipleValuesField
          textFields={saveValue}
          onChange={(textFields) => {
            setSaveValue(textFields)
          }}
          buttonLabel={buttonLabel}
          required={required}
        />
      )}
      onSave={() => {
        return onSave(saveValue)
      }}
      onClose={() => {
        setSaveValue(initialTextFields)
      }}
    />
  )
}
