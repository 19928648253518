import { useState } from "react"
import styled from "styled-components"

import { usePutParadiseUpdateDevice } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import {
  useFetchParadiseHome,
  useFetchParadiseHomes,
} from "src/data/paradise/paradiseHomes/queries/paradiseHomeQueries"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MBanner } from "src/ui/MBanner/MBanner"
import { IMSelectResult, MSelect } from "src/ui/MSelect/MSelect"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

interface IHomeDropDownResult extends IMSelectResult {
  homeName?: string
}

const FORM_ID = "transfer_device_form"

export function ParadiseDeviceTransferDialog({
  open,
  homeId,
  deviceId,
  onClose,
  resetOnClose = true,
}: {
  open: boolean
  homeId: string
  deviceId: string
  onClose: () => void
  resetOnClose?: boolean
}) {
  const [selectedHome, setSelectedHome] = useState<IHomeDropDownResult>({
    id: "",
    name: "",
  })
  const [searchedId, setSearchedId] = useState("")
  const [clickedTransfer, setClickedTransfer] = useState(false)

  const fetchHomes = useFetchParadiseHomes({
    filter: {
      home_id: searchedId,
    },
  })

  const putUpdateDevice = usePutParadiseUpdateDevice()

  const fetchCurrentHome = useFetchParadiseHome({
    homeId,
  })

  function handleTransfer() {
    if (!clickedTransfer) {
      setClickedTransfer(true)
      return
    }

    putUpdateDevice.mutate(
      {
        deviceId,
        body: {
          home: selectedHome.id,
        },
      },
      {
        onSuccess: () => {
          resetState()
          onClose()
        },
      }
    )
  }

  function resetState() {
    if (resetOnClose) {
      setSelectedHome({
        id: "",
        name: "",
      })
      setSearchedId("")
      setClickedTransfer(false)
    }
  }

  const dropDownOptions =
    fetchHomes.data?.homes.map((h) => ({
      id: h.id,
      name: h.id,
      homeName: h.name,
    })) ?? []

  return (
    <MDialog
      title="Transfer device"
      open={open}
      onClose={() => {
        onClose()
        resetState()
      }}
      formId={FORM_ID}
      confirmLabel={!clickedTransfer ? "Transfer" : "Confirm"}
      confirmButtonProps={{
        color: clickedTransfer ? "destructive" : "default",
        loading: putUpdateDevice.isLoading,
      }}
    >
      <FormWrapper
        id={FORM_ID}
        onSubmit={(e) => {
          e.preventDefault()
          handleTransfer()
        }}
      >
        <MText variant="body">Transfer device to a different home</MText>
        <MSelect
          options={dropDownOptions}
          value={selectedHome}
          onSearch={(input) => setSearchedId(input)}
          onSelected={(selectedHome) => {
            if (selectedHome) {
              setSelectedHome(selectedHome)
              setClickedTransfer(false)
            }
          }}
          label="Select home"
          getOptionDisabled={(option) => option?.id === homeId}
          required
          loading={fetchCurrentHome.isLoading || fetchHomes.isLoading}
        />
        {selectedHome.id && (
          <ConfirmationWrapper>
            <div>
              <MText variant="bodyS" color="secondary">
                Current home
              </MText>
              <MText variant="body">
                Id: {fetchCurrentHome.data?.id || "unknown"}
              </MText>
              <MText variant="body">
                Name: {fetchCurrentHome.data?.name || "unknown"}
              </MText>
            </div>
            <div>
              <MText variant="bodyS" color="secondary">
                New home
              </MText>
              <MText variant="body">Id: {selectedHome.id}</MText>
              <MText variant="body">Name: {selectedHome.homeName}</MText>
            </div>
          </ConfirmationWrapper>
        )}
      </FormWrapper>
    </MDialog>
  )
}

const FormWrapper = styled.form`
  display: grid;
  gap: ${spacing.L};
`

const ConfirmationWrapper = styled(MBanner)`
  display: grid;
  gap: ${spacing.M};
`
